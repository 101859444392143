#waveform {
  cursor: pointer;
}

.Controls {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 3.5em;
  padding: 2px 5px 2px 5px;
  width: max-content;
}

.VolumeSlider {
  min-width: 8em;
}
